.plot-chart-class {
    font-size: 12px;
}

[aria-label="y-axis label"] {
    font-size: 15px;
    font-family: "Avenir", "Avenir Next LT Pro", "Montserrat", "sans-serif";
}


[aria-label="x-axis label"] {
    font-size: 15px;
    font-family: "Avenir", "Avenir Next LT Pro", "Montserrat", "sans-serif";
}
